import { graphql, PageProps } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import * as React from "react";

import { Layout } from "@/components/Layout";
import { TagListItem } from "@/components/TagListItem";

const TagPage: React.FC<PageProps<Queries.TagListQuery>> = ({ data }) => {
  return (
    <Layout>
      <GatsbySeo title="Tag" />
      <div className="prose max-w-full pt-12 pb-8">
        <h1 className="md:text-6xl">Tag</h1>
      </div>
      <ul className="flex list-none flex-wrap gap-4">
        {data.allContentfulTag.nodes.map((props) => (
          <TagListItem {...props} />
        ))}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  query TagList {
    allContentfulTag {
      nodes {
        ...TagListItemProps
      }
    }
  }

  # This fragment is neccessary to generate interface of @/components/PostListItem
  fragment TagListItemProps on ContentfulTag {
    contentful_id
    name
  }
`;

export default TagPage;
